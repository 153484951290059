import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` hosted another bot demo night this week at Wilson Sonsini in San Francisco.`}</p>
    <p>{`It was a great line up of interesting bots — covering travel, entertainment, sports, games, social, reporting, and productivity tools.`}</p>
    <h2>{`Dashbot Launches Support for Kik`}</h2>
    <p>{`We kicked off the evening with a demo of `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`our analytics`}</a>{` platform support for `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/sdk/kik"
      }}>{`Kik`}</a>{` bots.`}</p>
    <p>{`We support all the rich messages of Kik bots including memes, videos, keyboards, sketches, and even stickers!`}</p>
    <p>{`Our Kik support features all the `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/tour"
      }}>{`same functionality`}</a>{` of our support for Slack and Facebook Messenger bots, including `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/sdk/pause"
      }}>{`Live Person Take Over`}</a>{` of chat sessions, real time analytics, and live transcripts — you can see more here: `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/tour"
      }}>{`www.dashbot.io/tour`}</a>{`.`}</p>
    <p>{`You can sign up for free at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`www.dashbot.io`}</a>{`.`}</p>
    <h2>{`Demos`}</h2>
    <p>{`The line up for the evening included:`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.acebot.ai/"
      }}><strong parentName="a">{`Acebot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155143/f94ad-1g43-htedsijxwszppt4fkg.png)
    </figure>
Ravi from [Acebot](http://www.acebot.ai/) demoed their Slack bot for expenses.
Dealing with expenses can be a pain in the butt — this is a great bot for making
the process easier.
    <p><a parentName="p" {...{
        "href": "http://www.hipmunk.com/"
      }}><strong parentName="a">{`Hipmunk`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155143/6012c-1ya8xdyfmyejjv5daqkxnaw.png)
    </figure>
Alex demoed the [Hipmunk](http://www.hipmunk.com/) bot — a great example in the
travel space. In addition to helping users find flights and hotels, the bot is
developing a personality — you can even ask it to tell a joke (spoiler: it’s a
little nutty…).
    <p><a parentName="p" {...{
        "href": "http://www.jimmie.com/"
      }}><strong parentName="a">{`Jimmie`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155142/70cd2-1c34neu7f9bylywwoxgnpwa.png)
    </figure>
Richard demoed [Jimmie](http://www.jimmie.com/) — a Facebook bot that connects
users together to chat about common interests and topics. Since the users are
connected through his bot, rather directly, the bot can provide an additional
layer of security for users.
    <p><a parentName="p" {...{
        "href": "http://www.machaao.com/"
      }}><strong parentName="a">{`Machaao`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155142/c7928-1uv8440_nltnllkb_f4kcna.png)
    </figure>
With more than a two billion fans, cricket is a very popular sport! Harshal and
team demoed the [Machaao](http://www.machaao.com/) Facebook bot — a great way to
follow cricket and keep up on scores and events during games. They plan to
expand to additional sports as well.
    <p><a parentName="p" {...{
        "href": "http://pogo.ai/"
      }}><strong parentName="a">{`Pogo`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155141/d1e26-1kyagb_ujfil7jobu5d_axw.png)
    </figure>
Liana demoed [Pogo](http://www.pogo.ai/) — a Slack bot that helps keep track of
links and messages. You can save and quickly find links and messages — saving
all the time of scrolling through previous messages.
    <p><a parentName="p" {...{
        "href": "https://www.facebook.com/SmartNotesBot/"
      }}><strong parentName="a">{`Smart Notes`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155141/711df-1myh_ogurf8cxrffg-nhq4q.png)
    </figure>
Ever email notes to yourself? Stefan created a better way to save notes and set
reminders through his bot, [Smart
Notes](https://www.facebook.com/SmartNotesBot/). Not only can you save notes,
you can share them with others via Facebook Messenger.
    <p><a parentName="p" {...{
        "href": "https://statsbot.co/"
      }}><strong parentName="a">{`Statsbot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155141/dbf21-1ilhgrcwseh81rk8set6j8q.png)
    </figure>
Artem and team demoed [Statsbot](http://www.statsbot.co/) — a great Slack bot
for quickly querying and pulling in data from different analytics platforms into
Slack. Ask for a summary of metrics for the past month, and it will appear right
there in Slack — no need to switch programs or go to another site.
    <p><a parentName="p" {...{
        "href": "http://www.streaktrivia.com/"
      }}><strong parentName="a">{`Streak Trivia`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/09/07155140/5bae9-1ik-34_qxpdx2c9ncrwziig.png)
    </figure>
Max and James rounded out the demos on a fun, interactive high note — leading
the audience in a round of trivia with their Facebook bot, [Streak
Trivia](http://www.streaktrivia.com/). It’s a multiplayer trivia game. The game
was a lot of fun and went on for quite a while — a lot of trivia buffs were
present. [Rhett Creighton](https://twitter.com/HeyRhett) ended up winning the
round.
    <h2>{`Stay Tuned for Next Month’s Event`}</h2>
    <p>{`It was great seeing a lot of familiar faces as well as so many new ones. Once again it was a full house with many people staying well after the demos to continue networking with other bot enthusiasts. It’s an exciting space and definitely exciting times!`}</p>
    <p>{`If you’re interested in demoing at one of upcoming events, `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/contact"
      }}>{`let us know`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      